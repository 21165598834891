.ot-default-button {
    padding: 8px 10px;
    cursor: pointer;
    border: none;
    background-color: #5d78ff;
    color: #fff;
    min-width: 205px;
    height: 55px;
    border-radius: 27.5px;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.1px;
    &.ot-button--small {
        display: block;
        min-width: 75px;
        height: 40px;
        border-radius: 20px;
    }
    &.ot-button--medium {
        min-width: 140px;
        height: 45px;
        border-radius: 20px;
    }
    &.ot-button--xsmall {
        min-width: 75px;
        height: 35px;
        border-radius: 20px;
        padding: 3px 10px;
        line-height: 1em;
        font-weight: 400;
    }
    &[disabled] {
        opacity: 0.5;
    }
}

.ot-secondary-button {
    padding: 8px 10px;
    cursor: pointer;
    border: none;
    background-color: #D84B06;
    color: #fff;
    min-width: 205px;
    height: 55px;
    border-radius: 27.5px;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.1px;
    &.ot-button--small {
        display: block;
        min-width: 75px;
        height: 40px;
        border-radius: 20px;
    }
    &.ot-button--medium {
        min-width: 140px;
        height: 45px;
        border-radius: 20px;
    }
    &.ot-button--xsmall {
        min-width: 75px;
        height: 35px;
        border-radius: 20px;
        padding: 3px 10px;
        line-height: 1em;
    }
    &[disabled] {
        opacity: 0.5;
    }
}

.ot-stroked-button {
    padding: 8px 10px;
    cursor: pointer;
    border: none;
    border: 2px solid #5d78ff;
    color: #5d78ff;
    background-color: transparent;
    min-width: 205px;
    height: 55px;
    border-radius: 27.5px;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.1px;
    line-height: 1em;
    &.ot-button--small {
        display: block;
        min-width: 75px;
        height: 40px;
        border-radius: 20px;
    }
    &.ot-button--xsmall {
        min-width: 75px;
        height: 35px;
        border-radius: 20px;
        padding: 3px 10px;
        font-weight: 400;
        line-height: 1em;
    }
    &.ot-button--medium {
        min-width: 140px;
        height: 45px;
        border-radius: 20px;
    }
    &[disabled] {
        opacity: 0.5;
    }
}

.ot-button {
    background-color: #D84B06;
    color: white;
    padding: 5px 60px 5px 60px;
    border-color: white;
    font-size: 1.2em;
}

.ot-link {
    cursor: pointer;
    padding: 5px 60px 5px 60px;
    font-size: 1.2em;
}

.ot-button-sm {
    height: 30px;
    width: 77px;
}

.button-link {
    text-decoration: underline;
    color: #003778;
}

.ot-yellow-button {
    width: 217px;
    height: 63px;
    background: #e8e8e8 0% 0% no-repeat padding-box;
    border-radius: 32px;
    opacity: 1;
    color: white;
    font-size: 1.3em;
}

button.ot-stroked-button.ot-button--xsmall:hover {
    background: #5d78ff;
    color: white;
}

.btn.btn-success.btn-shadow {
  -webkit-box-shadow: 0px 9px 16px 0px rgba(27, 197, 189, 0.25) !important;
  box-shadow: 0px 9px 16px 0px rgba(27, 197, 189, 0.25) !important;
}

.btn.btn-light-dark {
  color: #181C32;
  background-color: #D1D3E0;
  border-color: transparent;
}
