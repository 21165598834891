.otrack-table {
  font-family: inherit !important;
  .mat-header-row {
    background-color: lavender;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    min-height: 39px;

    .mat-header-cell {
      color: black !important;
      font-size: 0.98rem !important; // 1.1rem !important;
    }
  }

  .mat-cell:last-child, .mat-footer-cell:last-child, .mat-header-cell:last-child {
    margin-right: 10px !important;
  }


  .mat-row {
    min-height: 34px;
    cursor: pointer !important;

    .mat-cell {
      font-size:0.9rem;
      font-weight: 400;
    }
  }

   .right {
        display: flex;
        justify-content: flex-end;
    }

  .otrack-inner-table {
    border: 1px solid;
    margin: 10px;
    font-family: inherit !important;
    .mat-header-row {
      background-color: #e8e2e8;
    }


  }
}
