.ag-theme-material .ag-cell{
    font-size: 1rem;
    font-weight: 400;
    padding: 0px 10px 0 10px;
}

.ag-theme-material .ag-header-cell{
  // border-right: 1px solid #eaeaea;
  // background: #f5f5f5;
    background: aliceblue;
    font-weight: 600;
    padding: 0px 10px 0px 10px;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    font-size: 1rem;
}
