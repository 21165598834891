/* You can add global styles to this file, and also import other style files */

@import "assets/vendors/global/vendors.bundle.css";
@import "assets/css/pages/wizard/wizard-2.css";
@import "assets/css/pages/wizard/wizard-4.css";
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import "~material-design-icons/iconfont/material-icons.css";
@import "~highlight.js/styles/googlecode.css";
// @import "../node_modules/ag-grid-community/dist/styles/ag-grid.css";
// @import "../node_modules/ag-grid-community/dist/styles/ag-theme-alpine.css";
@import "~@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
@import "~@ag-grid-enterprise/all-modules/dist/styles/ag-theme-alpine.css";
// ng-select themes
@import "~@ng-select/ng-select/themes/default.theme.css";
// @import "~@ng-select/ng-select/themes/material.theme.css";
@import './scss/custom/_report';
@import './scss/custom/_print';
@import './scss/custom/_table';
@import './scss/custom/_custom';
@import './scss/custom/_agtable';
@import './scss/custom/_button';
// hide bootstrap dropdown arrow globally
.dropdown-toggle::after {
    display: none !important;
}

.mat-table__wrapper--dashboard .mat-table {
    min-width: 600px !important;
    width: 100% !important;
}

.position-static {
    position: static;
}

.mat-table__wrapper .mat-table .mat-row {
    //border-bottom: 0.1px solid #cccccc !important;
}

.error-snackbar {
    background: #ff5a5a !important;
    color: white !important;
    font-weight: 500 !important;
}

.error-snackbar .mat-button-wrapper {
    color: white !important;
    font-weight: 500 !important;
}

.success-snackbar {
    background: #5f9e64 !important;
    color: white !important;
    font-weight: 500 !important;
}

.success-snackbar .mat-button-wrapper {
    color: white !important;
    font-weight: 500 !important;
}

.warn-snackbar {
    background: #ffd84c !important;
    color: white !important;
    font-weight: 500 !important;
}

.warn-snackbar .mat-button-wrapper {
    color: white !important;
    font-weight: 500 !important;
}

.permission-access-hide {
    display: none !important;
}

.mat-progress-spinner circle,
.mat-spinner circle {
    stroke: #f9d5a4;
}

.dz-message {
    display: none !important;
}

button.dz-button {
    display: none !important;
}

.m-auto {
    margin: auto;
}
