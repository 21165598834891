.report-table-header {
  border-top: 1px solid black !important;
  border-bottom: 1px solid black !important;
}

.report-table-header-cell {
  border: 0 !important;
  color: black !important;
}

.report-table-row {
  // border-bottom: 0.5px solid #ffffff !important;
  border: 0px !important;
}

.report-table-cell {
  border: 0 !important;
}

.report-column-small-cell {
  flex: 0 0 11% !important;
  min-width: 104px !important;
}

.report-column-xsmall-cell {
  flex: 0 0 10% !important;
  min-width: 80px !important;
}

.report-column-xxsmall-cell {
  flex: 0 0 8% !important;
  min-width: 70px !important;
}

.report-column-xxxsmall-cell {
  flex: 0 0 4% !important;
  min-width: 50px !important;
}

.report-column-xxxxsmall-cell {
  flex: 0 0 3% !important;
  min-width: 40px !important;
}

.report-column-work-break-cell {
  word-break: break-word !important;
}

.report-cell-right {
  display: flex;
  justify-content: flex-end;
}

.report-cell-center {
  display: flex;
  justify-content: center;
}

.report-table-footer {
  font-weight: 1000;
}

mat-header-cell.report-table-header-cell {
  font-weight: 700;
}

.report-column-warning-icon {
  margin-left: 10px;
  font-size: 18px;
}
